import React, {FunctionComponent, useState, useMemo} from 'react';
import parse from 'html-react-parser';

import Button from '../button/Button';
import MarkRounded from '../svg/MarkRounded';
import Block from '../../../domain/Block/Block';
import BlockFormComponent from './BlockFormComponent';
import BlockPreviewComponent from './BlockPreviewComponent';
import {Popover} from 'react-tiny-popover';

// Redux
import {useAppDispatch, useAppSelector} from '../../../store/hook';
import ValidateConditionUseCase from '../../../domain/Condition/UseCase/ValidateConditionUseCase';
import FormGateway from '../../../gateway/Form/FormGateway';
import {isVisibleConditionBlock} from '../../util/validateCondition';
import {updateBlock} from "../../../store/block/blocks";

type Props = {
  pageId: string,
  blockId: string
};

interface CustomComponentProps extends React.ComponentPropsWithoutRef<'div'> {
  onMouseEnter(): void;

  onMouseLeave(): void;
}

const CustomComponent = React.forwardRef<HTMLDivElement, CustomComponentProps>((props, ref) => (
  <div ref={ref} onMouseEnter={props.onMouseEnter} onMouseLeave={props.onMouseLeave}>
    {props.children}
  </div>
));

const BlockComponent: FunctionComponent<Props> = ({pageId, blockId}) => {
  const blocksStringOrObject = useAppSelector(state => state.blocks.all)

  const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
  const [block, setBlock] = useState<Block | null>(null)
  const [validationState, setValidationState] = useState<boolean>(false)
  const storeIsBack = useAppSelector(state =>  state.wizzardStep.isBack)
  const dispatch = useAppDispatch()

  useMemo(() => {
    const blocks = JSON.parse(blocksStringOrObject)
    const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
    const datas = validateConditionUseCase.formGatewayInterface.getCurrentValueForFormId()
    const blocksVisible = blocks.filter(block => isVisibleConditionBlock(block, validateConditionUseCase.executeWithDatas(block.condition, datas)))
    if (storeIsBack) {
      blocksVisible.forEach(function(b, index, array) {
        if (b.id === blocksVisible[blocksVisible.length - 1].id) {
          dispatch(updateBlock({'id': b.id, 'state': 'progress', 'stateNext': 'waiting'}))
        } else {
          dispatch(updateBlock({'id': b.id, 'state': 'complete', 'stateNext': 'waiting'}))
        }
      });
    }

    const blockFromStore = (blocks.filter(block => block.id === blockId))[0]
    setBlock(blockFromStore)

    if (blockFromStore && undefined !== blockFromStore.condition) {
      const validateConditionUseCase = new ValidateConditionUseCase(new FormGateway())
      setValidationState(validateConditionUseCase.execute(blockFromStore.condition))
    }

  }, [blocksStringOrObject, blockId])

  const isVisible = isVisibleConditionBlock(block, validationState)

  return (<>
      {(block &&
        <>
          {
            (['complete', 'validate'].includes(block.state) && isVisible) &&
            <BlockPreviewComponent key={block.id} block={block} state={block.state} pageId={pageId} role="" />
          }
          {
            (block.state === 'waiting' && isVisible) &&
            <BlockPreviewComponent key={block.id} pageId={pageId} block={block} state="disable" role="" />
          }
          {
            ((!['complete', 'waiting'].includes(block.state)) && isVisible) &&
            <div
              className={(block.cssClasses || '') + ` box-elevations box-elevations--base box-elevations--mb u-mxAuto`}>
              <h2 className="help-wrapper u-mbs">
                {block.title}
                {block.help && (
                  <>
                    <Popover
                      isOpen={isPopoverOpen}
                      positions={['bottom']}
                      align={'end'}
                      padding={10}
                      onClickOutside={() => setIsPopoverOpen(false)}
                      content={<div className="box-helpers">{parse(block.help)}</div>}>
                      <CustomComponent onMouseEnter={() => setIsPopoverOpen(true)}
                                       onMouseLeave={() => setIsPopoverOpen(false)}>
                        <Button classes="help__button" label="" position="alone" icon={<MarkRounded />} />
                      </CustomComponent>
                    </Popover>
                  </>
                )}
              </h2>
              <BlockFormComponent pageId={pageId} block={block} />
            </div>
          }
        </>
      )}
    </>
  )
}

export default BlockComponent;

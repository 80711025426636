import React, {FunctionComponent, InputHTMLAttributes, useState} from 'react';
import {useHistory} from "react-router";
import {useTranslation} from 'react-i18next';

import {FolderDetail} from "../../../domain/Folder/Folder";
import storage from "../../util/storage";
import FetchFolderGateway from '../../../gateway/Folder/FetchFolderGateway';
import GetDetailAndStoreItUseCase from '../../../useCase/Folder/GetDetailAndStoreItUseCase';
import LocalStorageGateway from '../../../gateway/Form/LocalStorageGateway';
import AbandonedFiles from "../svg/AbandonedFiles";
import {Popover} from "react-tiny-popover";
import Modal from "../modal/Modal";
import useModal from "../modal/useModal";
import {EFolderStatus} from "../../../domain/Folder/EFolderStatus";
import {ERole} from "../../../domain/User/Erole";
import Converter from "../../util/Converter";
import PageLoader from "../pageLoader/PageLoader";
import envVariable from "../../util/envVariable";

type Props = {
    FolderDetail: FolderDetail
    handleDeleteFolder: (id: string) => void
}

const FolderComponent: FunctionComponent<Props> = ({handleDeleteFolder, FolderDetail}) => {
    const history = useHistory()
    const {t} = useTranslation()

    const role = localStorage.getItem('role') ?? '';

    const [isShowing, toggle] = useModal();
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false)
    const [error, setError] = useState<boolean>(false)

    const statusHideAllButton: string[] = [EFolderStatus.Canceled, EFolderStatus.Rejected];
    const roleHideButtonDeleted: string[] = [ERole.Client, ERole.Prospect, ERole.Back_office_user];
    const roleHideButtonModify: string[] = [ERole.Back_office_user];
    const roleHideButtonHistorySignature: string[] = [ERole.Client, ERole.Prospect];
    const statusHideButtonDeleted: string[] = [EFolderStatus.Signed];
    const [isLoading, setLoading] = useState<boolean>(false)
    const [showDocusignHistory, setShowDocusignHistory] = useState<boolean>(false)
    const allowDownloadDocument = (envVariable('REACT_APP_ALLOW_DOWNLOAD_DOCUMENT') === 'true')
    
    const [showEnvelopeHistoryModal, toggleEnvelopeHistory] = useModal();
    const [contentModal, setContentModal] = useState<InputHTMLAttributes<string>>();
    const [modalSize, setModalSize] = useState<string>('modal');

    function handleEdit() {
        storage.setFolderId(FolderDetail.id)
        storage.setDossierState(FolderDetail.status)
        const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())

        getDetailAndStoreItUseCase.execute(FolderDetail.id).then((response) => {
            if (response !== null) {
                history.push(response.url)
            }
        })
    }

    function handleDelete() {
        const folderGateway = new FetchFolderGateway()
        folderGateway.updateFolderStatusToCanceled(FolderDetail.id)
            .then(() => {
                handleDeleteFolder(FolderDetail.id)
                setError(false)
            })
            .catch((e) => {
                console.error(e)
                setError(true)
            })
    }

    function handleDownload() {
        setLoading(true)
        storage.setFolderId(FolderDetail.id)
        const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())
        getDetailAndStoreItUseCase?.downloadSignedDocument(FolderDetail.id)?.then((response) => {
            if (response !== null) {
                const blob = new Converter().dataURItoBlob(response.contentBase64)
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                document.body.appendChild(a);
                a.href = url;
                a.download = response.filename;
                a.click();
                window.URL.revokeObjectURL(url);
            }
            setLoading(false);
        })
        .catch((error) => {
            setLoading(false);
            if (error?.response?.data?.detail?.includes('ENVELOPE_DOES_NOT_EXIST')) {
                alert(t('docusign.error-folder-not-found'))
            } else {
                alert(t('docusign.error-content'))
            }
            setLoading(false);
        })
    }

    function handleDocuSignHistory() {
        setShowDocusignHistory(true)
        storage.setFolderId(FolderDetail.id)
        const getDetailAndStoreItUseCase = new GetDetailAndStoreItUseCase(new FetchFolderGateway(), new LocalStorageGateway())
        getDetailAndStoreItUseCase?.getFolderDocuSignHistory(FolderDetail.id)?.then((response) => {
            setContentModal(buildModalContent(response))          
            setShowDocusignHistory(false);
        })
        .catch((error) => {
            setShowDocusignHistory(false);
            if (error?.response?.data?.detail?.includes('ENVELOPE_DOES_NOT_EXIST')) {
                alert(t('docusign.error-folder-not-found'))
            } else {
                alert(t('docusign.error-content'))
            }
        })
    }

    const buildModalContent = (response) => {
        setModalSize('modal-xl')
        toggleEnvelopeHistory()

        if (response) {
            const resource = `data:application/pdf;base64,${response.envelopeHistoryData}`

            return (
                <div className="pj-modal-content-center">
                    <iframe src={resource} width="100%" style={{ height: '80vh' }}/>
                </div>
            )
        } else {
    
            return (
                <div className="pj-modal-content-center">
                  <p className="notification notification--error">{t('docusign.error-folder-not-found')}</p>
                </div>
            )
        }
    }

    const FolderStatuses = FolderDetail.status.split('|').map(status => t(`folder.state.${status}`))

    return (
        <>
            {showEnvelopeHistoryModal && 
                <Modal title={t('docusign.modal-preview-title')}
                        description=''
                        customClassName={modalSize}
                        hide={toggleEnvelopeHistory}>
                    {contentModal}
                </Modal>
            }

            <div className="list__line" key={FolderDetail.id}>
                <div>
                    <div className="u-txt-size-l u-txt-bold u-mb0">{FolderDetail.present}</div>
                    <div className="u-txt-size-s u-txt-color-ink-1">
                        <span>{t('common.folder')} n° {FolderDetail.id}</span>
                        {FolderDetail.advisor?.external_id && (<>
                        <span>&nbsp;</span>
                        <span>({FolderDetail.advisor?.external_id})</span>
                        </>)}
                    </div>
                    <div className="u-txt-size-xs u-mb0 u-max-w-90">({FolderStatuses.join(' - ')})</div>
                    {
                        error
                        && <span className="flex u-mts u-txt-size-s notification notification--error">
                            {t('folder.error.cannot-delete-subscription-folder')}
                        </span>
                    }
                </div>
                { isLoading && <PageLoader/> }
                { showDocusignHistory && <PageLoader description={t('docusign.display-history-loader')}/> }
                <div className="flex">
                    {!statusHideAllButton.includes(FolderDetail.status) && (
                        <>
                            {allowDownloadDocument && (!roleHideButtonModify.includes(role) &&
                                (FolderDetail.status === 'signature' || FolderDetail.status === 'signed')) && (
                                <button onClick={() => handleDownload()}
                                        className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                                        data-id={FolderDetail.id}
                                >
                                    {t('button.download-document')}
                                </button>
                            )}

                            {(!roleHideButtonHistorySignature.includes(role) &&
                                (FolderDetail.status === EFolderStatus.Signature || FolderDetail.status === EFolderStatus.Signed)) && (
                                <button onClick={() => handleDocuSignHistory()}
                                        className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                                        data-id={FolderDetail.id}
                                >
                                    {t('docusign.history')}
                                </button>
                            )}


                            {(!roleHideButtonModify.includes(role) && 
                                (FolderDetail.status !== EFolderStatus.Signature && FolderDetail.status !== EFolderStatus.Signed)) && (
                                <button onClick={() => handleEdit()}
                                        className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                                        data-id={FolderDetail.id}
                                >
                                    {t('button.modify')}
                                </button>
                            )}
                            {roleHideButtonModify.includes(role) && (
                                <button onClick={() => handleEdit()}
                                        className="button-reset button-primary--outline button--medium button--width-fit u-mrb"
                                        data-id={FolderDetail.id}
                                >
                                    {t('button.view')}
                                </button>
                            )}
                            {(!roleHideButtonDeleted.includes(role) && !statusHideButtonDeleted.includes(FolderDetail.status)) && (
                                <>
                                    <Popover
                                        isOpen={isPopoverOpen}
                                        positions={['right']}
                                        align={'center'}
                                        padding={10}
                                        content={<div className="box-helpers">{t('folder.abandon-folder')}</div>}
                                    >
                                        <button data-id={FolderDetail.id}
                                                onMouseEnter={() => setIsPopoverOpen(true)}
                                                onMouseLeave={() => setIsPopoverOpen(false)}
                                                className="button-reset button--width-fit button--files-state"
                                                onClick={() => {
                                                    setError(false)
                                                    toggle()
                                                }}
                                        >
                                            <AbandonedFiles color="var(--ink-6)"/>
                                        </button>
                                    </Popover>
                                </>
                            )}
                        </>)
                    }
                </div>
                {isShowing && <Modal title={t('folder.modal-cancellation.title')}
                                    description={t('folder.modal-cancellation.description')}
                                    hide={toggle}
                >
                    <div className="modal-container-buttons">
                        <button type="button"
                                className="button-reset button-primary--outline button--medium button--width-fit"
                                onClick={toggle}
                        >
                            {t('folder.modal-cancellation.no-i-decline')}
                        </button>
                        <button type="button"
                                className="button-reset button-error--outline button--medium button--width-fit"
                                onClick={handleDelete}
                        >
                            {t('folder.modal-cancellation.yes-i-delete-subscription-folder')}
                        </button>
                    </div>
                </Modal>}
            </div>
        </>
    )
}

export default FolderComponent;
